<template>
    <td>
        <div v-if="props.notification.statusTypeId == 1" class="notif-div">
            <MDBBadge color="success" pill class="d-inline" style="padding: 7px 15px;">NEW</MDBBadge>
        </div>
        <div v-else-if="props.notification.statusTypeId == 2" class="notif-div">
            <MDBBadge color="primary" pill class="d-inline" style="padding: 7px 15px;">VIEWED</MDBBadge>
        </div>
        <div v-else-if="props.notification.statusTypeId == 4" class="notif-div">
            <MDBBadge color="info" pill class="d-inline" style="padding: 7px 15px;">QUALIFIED</MDBBadge>
        </div>
        <div v-else-if="props.notification.statusTypeId == 5" class="notif-div">
            <MDBBadge color="danger" pill class="d-inline" style="padding: 7px 15px;">NOT QUALIFIED</MDBBadge>
        </div>
        <div v-else class="notif-div">
            <MDBBadge color="warning" pill class="d-inline" style="padding: 7px 15px;">INGESTED</MDBBadge>
        </div>
    </td>
    <td>
        <template v-if="props.notification.statusTypeId == 1">
            <div class="new-notification-word">
                {{ props.notification.notificationWord }}
            </div>
        </template>
        <div v-else class="notification-word">
            {{ props.notification.notificationWord }}
        </div>
    </td>
    <td>
        {{ props.notification.socialMediaPlatform }}
    </td>
    <td>
        {{ props.notification.dateAdded }}
    </td>
    <td>
        {{ props.notification.creatorName }}
    </td>
    <td>
        <MDBDropdown v-model="rowOverflow" class="kebab">
            <MDBDropdownToggle @click.stop="rowOverflow = !rowOverflow" color="primary" size="sm" class="fw-bold">
                Action
            </MDBDropdownToggle>
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                <MDBDropdownItem href="#" @click.stop.prevent="dropdownAction(props.notification, 'qualified')">Mark as
                    Qualified</MDBDropdownItem>
                <MDBDropdownItem href="#" @click.stop.prevent="
                    dropdownAction(props.notification, 'notQualified')
                    ">Mark as Not Qualified</MDBDropdownItem>
                <MDBDropdownItem href="#" @click.stop.prevent="dropdownAction(props.notification, 'reviewed')">Mark as
                    Ingested</MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    </td>
</template>

<script>
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBadge } from "mdb-vue-ui-kit";
import { ref } from "vue";
import axios from "axios";
import { useToastStore } from "@/store/toastStore";

export default {
    components: {
        MDBDropdown,
        MDBDropdownToggle,
        MDBDropdownMenu,
        MDBDropdownItem,
        MDBBadge,
    },
    props: ["notification"],
    setup(props) {
        const rowOverflow = ref(false);
        const toastStore = useToastStore();
        const dropdownAction = (row, action) => {
            var status = 3; // reviewed
            var successMessage = "Successfully tagged as reviewed!";
            if (action == "qualified") {
                status = 4;
                successMessage = "Successfully tagged as qualified!";
            } else if (action == "notQualified") {
                status = 5;
                successMessage = "Successfully tagged as not qualified.";
            }

            updateNotificationStatus(row.id, status, successMessage);
            rowOverflow.value = false;

            row.statusTypeId = status;
        };

        const updateNotificationStatus = (
            notificationId,
            statusId,
            successMessage
        ) => {
            const toastSuccess = {
                ...toastStore.toastStatus.success,
                ...{
                    message: successMessage,
                },
            };

            const formData = {
                status: statusId,
            };

            var url = "api/notifications/" + notificationId + "/status";
            axios
                .patch(url, formData, {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                    },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        toastStore.toastObject = {
                            ...toastStore.toastObject,
                            ...toastSuccess,
                        };
                    }

                    if (response.status == 422) {
                        toastStore.toastObject = {
                            ...toastStore.toastObject,
                            ...toastStore.toastStatus.fail,
                        };
                    }
                })
                .catch(function () {
                    toastStore.toastObject = {
                        ...toastStore.toastObject,
                        ...toastStore.toastStatus.fail,
                    };
                });
        };

        return {
            rowOverflow,
            props,
            dropdownAction,
        };
    },
};
</script>

<style lang="scss" scoped>
.notification-word {
    font-weight: 400;
}

.new-notification-word {
    font-weight: 600;
}

.notif-div {
    display: flex;
    width: 100%;
}

.btn-primary {
    background-color: var(--primary);
}
</style>
